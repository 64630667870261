import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import ServiceHero from "../../components/service-hero";
import ReviewSection from "../../components/review-section";
import ImageSection from "../../components/image-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Certifications from "../../components/certifications";

const ConsumerUnitsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			CUImg: wpMediaItem(title: { eq: "Josh-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			TImg: wpMediaItem(title: { eq: "Consumer-Units-Image-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const CUImg = data.CUImg?.localFile.childImageSharp.gatsbyImageData;
	const TImg = data.TImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Consumer Unit",
				item: {
					url: `${siteUrl}/services/consumer-unit`,
					id: `${siteUrl}/services/consumer-unit`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Consumer Unit | Denis Bonnici Electricians Ltd"
				description="If you have a fuseboard that is wooden-bodied, fusewire, cartridges, china-clad with asbestos flashguards – It is time for an upgrade!"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/consumer-units`,
					title: "Consumer Unit | Denis Bonnici Electricians Ltd",
					description:
						"If you have a fuseboard that is wooden-bodied, fusewire, cartridges, china-clad with asbestos flashguards – It is time for an upgrade!",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero backgroundImage={heroImg} title="Consumer Unit" />
			<section className="py-md-7 pb-4">
				<ImageSection
					title="Fuseboards"
					images={
						<div>
							<GatsbyImage
								className="w-md-100 w-lg-auto"
								image={CUImg}
								alt={data.CUImg?.altText}
							/>
							<GatsbyImage
								className="mt-5 w-md-100 w-lg-auto"
								image={TImg}
								alt={data.TImg?.altText}
							/>
						</div>
					}
					text={
						<span>
							<p>How old is your fuseboard?</p>
							<p>Does it have RCD protection?</p>
							<p>
								If you have a fuseboard that is wooden-bodied, fusewire,
								cartridges, china-clad with asbestos flashguards – It is time
								for an upgrade!
							</p>
							<p>
								To comply with BS7671 18th Edition 2017, a minimum of two RCD’s
								should be installed within Consumer units. RCD’s are a life
								saving device, preventing electric shocks by automatically
								shutting off the electrics if a fault is detected eg. water
								ingress or faulty appliance.
							</p>
							<p>
								Circuits can also be individually protected by the installation
								of RCBO’s which is highly recommended.
							</p>
							<p>
								The latest update to the Consumer unit regulations was in
								January 2017 where all units are now made in metal and fire
								retardant to 30 minutes.
							</p>
							<p>
								A high percentage of fires in the UK are caused by electrical
								fault, visit:{" "}
								<a
									target="_blank"
									rel="noreferrer"
									className="text-dark text-decoration-underline"
									href="https://www.electricalsafetyfirst.org.uk/"
								>
									www.electricalsafetyfirst.org.uk
								</a>
							</p>
						</span>
					}
				/>
			</section>
			<section className="pt-6 pb-7 ">
				<Certifications />
			</section>
			<section className="pb-md-7 pb-4">
				<ReviewSection />
			</section>
		</Layout>
	);
};

export default ConsumerUnitsPage;
